import styled from 'styled-components';
import { ButtonContained } from 'ui/elements/Button/styles'

export const ImageContainer = styled.div`
`;

export const PageContent = styled.div`
  padding: 15px 0;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const PageTeaser = styled.div`
  position: relative;

  ${ButtonContained} {
    margin-top: 0;
  }
`;
