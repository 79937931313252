import React from 'react';
import _get from 'lodash/get';
import Image from '../Image';

import { useLocalize } from 'utils/localize';

import * as S from './styles';
import ContentBox from '../ContentBox';
import Link from '../Link';
import { useTranslation } from 'gatsby-plugin-react-i18next'
import OpticalHeading from 'ui/elements/Heading/OpticalHeading'
import Button from 'ui/elements/Button'

const PageTeaser = ({
  page: rawPage,
}) => {
  const [t] = useTranslation('common')
  const page = useLocalize(rawPage.page);

  if (!rawPage.page) return null;

  const hero = _get(page, 'hero.image');

  return (
    <S.PageTeaser>
      <Image
        {...hero}
        width={980}
        height={450}
      />
      <S.PageContent>
        <Link to={`/${rawPage.slug.current}`}>
          <OpticalHeading level={4} color='primary'>
            {page.title}
          </OpticalHeading>
        </Link>
        {
          page.content && (
            <ContentBox blocks={page.content} truncateLines={2} />
          )
        }
      </S.PageContent>
      <Button as={Link} to={`/${rawPage.slug.current}`}>{t('readMore')}</Button>
    </S.PageTeaser>
  )
};

export default PageTeaser;
